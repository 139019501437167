export const state = () => ({
  iframeUrl: undefined,
  loading: false,
  userId: '',
  error: {},
  livecardGameData: []
})

export const mutations = {
  set_data(state, data) {
    state.sessionId = data
  },

  isMobile() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      return true
    } else {
      return false
    }
  },
  set_error(state, data) {
    state.error = data
  },
  set_loading(state, data) {
    state.loading = data
  },
  set_data_livecard_game(state, data) {
    state.livecardGameData = data
  },
  set_psb_token(state, data) {
    localStorage.psbToken = data
  }
}

export const actions = {
  // session id
  async getPremiumSportbookSessionID({ commit }) {
    const _accessToken = this.$auth.getToken('customLocal')
    try {
      commit('set_loading', true)
      const response = await this.$axios({
        method: 'post',
        baseURL: process.env.PREMIUM_SPORTSBOOK_URL,
        url: '/session/createSession',
        data: {
          _accessToken
        }
      })
      if (response.data) {
        commit('set_data', response.data.data)
      }

      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  },
  async destorySessionID({ commit }, sessionId) {
    const token = sessionId
    try {
      commit('set_loading', true)
      const response = await this.$axios({
        method: 'post',
        baseURL: process.env.PREMIUM_SPORTSBOOK_URL,
        url: 'session/destroySession',
        data: {
          token
        }
      })
      if (response && response.data) {
        commit('set_data', response.data)
      }
      commit('set_loading', false)
    } catch (error) {
      console.log(error)
      commit('set_error', error)
      commit('set_loading', false)
    }
  },
  async getPsbToken({ commit }) {
    try {
      commit('set_loading', true)
      const response = await this.$axios({
        method: 'post',
        baseURL: process.env.PREMIUM_SPORTSBOOK_URL,
        url: '/jwtEncDrc/signJwt',
        data: {
          iss: process.env.BRAND_ID,
          sub: this.$auth.user._id,
          name: this.$auth.user.username,
          // jti: instanceUUID.generateRandomUUID(),
          lang: process.env.LANGUAGE_PREMIUM,
          currency: this.$auth.user.currencyType
        }
      })
      if (response?.data) {
        commit('set_psb_token', response.data?.data)
      }

      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  }
}
