import { showHrAndGhr } from '~/const'

export default function ({ store, params, route, redirect, env, $auth }) {
  if (route.name === 'favorites') {
    if (
      !$auth.loggedIn ||
      ($auth.loggedIn && $auth.user && !$auth.user.sportsbook)
    ) {
      return redirect('/')
    }
  } else if (
    route.name === 'inplay' ||
    route.name === 'aml-policies' ||
    route.name === 'sportsbook' ||
    route.name === 'sportsbook-category' ||
    route.name === 'sportsbook-category-subCategory' ||
    route.name === 'sportsbook-category-subCategory-id-betradar' ||
    route.name === 'sportsbook-category-id'
  ) {
    if (env.ONLY_LOGIN && env.ONLY_LOGIN === 'true' && !$auth.loggedIn) {
      return redirect('/')
    }
    if (
      !showHrAndGhr &&
      (route.params?.category === 'horse-racing' ||
        route.params?.category === 'greyhound-racing')
    ) {
      return redirect('/')
    }
    if ($auth.loggedIn && $auth.user && route.params && route.params.category) {
      if (
        (route.params.category === 'horse-racing' && !$auth.user.horseRacing) ||
        (route.params.category === 'greyhound-racing' &&
          !$auth.user.greyhoundRacing) ||
        (route.params.category === 'Cricket' && !$auth.user.cricket) ||
        (route.params.category === 'Soccer' && !$auth.user.soccer) ||
        (route.params.category === 'Tennis' && !$auth.user.tennis) ||
        !$auth.user.sportsbook
      ) {
        return redirect('/')
      }
    }
  } else if (
    route.name === 'casino-live-casino' ||
    route.name === 'live-cards' ||
    route.name === 'live-cards-id' ||
    route.name === 'premium-sportsbook' ||
    route.name === 'casino-slots' ||
    route.name === 'live-games' ||
    route.name === 'casino-slots-id' ||
    route.name === 'racing' ||
    route.name === 'finsa' ||
    route.name === 'wac' ||
    route.name === 'rummy-poker' ||
    route.name === 'casino'
  ) {
    // if (
    //   route.name === 'premium-sportsbook' &&
    //   process.env.APP_TITLE !== 'FairPlay' &&
    //   process.env.APP_TITLE !== 'SuperWin'
    // ) {
    //   return redirect('/')
    // }
    if (!$auth.loggedIn) {
      return redirect('/login')
    } else if (
      route.name === 'casino-live-casino' &&
      $auth.loggedIn &&
      $auth.user &&
      !$auth.user.casino
    ) {
      return redirect('/login')
    } else if (
      (route.name === 'live-cards' || route.name === 'live-cards-id') &&
      $auth.loggedIn &&
      $auth.user &&
      !$auth.user.cardgame
    ) {
      return redirect('/login')
    }
    // else if (
    //   route.name === 'premium-sportsbook' &&
    //   $auth.loggedIn &&
    //   $auth.user &&
    //   !$auth.user.premiumsportsbook
    // ) {
    //   return redirect('/login')
    // }
  }
  //   if (env.ONLY_LOGIN && env.ONLY_LOGIN === 'true' && !$auth.loggedIn) {
  //     return redirect('/login')
  //   }
}
